import React, {useEffect, useState} from "react"
import { Link } from "gatsby"

import { Helmet } from 'react-helmet'

import Image from './Image'
import Tooltip from './Tooltip'
import DarkModeToggle from '../components/DarkModeToggle'

import Nav from './Nav'
import { slide as Menu } from 'react-burger-menu'

import linksStyles from "./basicComponents/links.module.css"
import colorStyles from "./basicComponents/colors.module.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faDatabase, faExternalLinkAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faNodeJs, faReact, faGithub } from '@fortawesome/free-brands-svg-icons'
// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false; /* eslint-disable import/first */

import '../pages/layout.css'
import "@fontsource/titillium-web"



function Layout(props) {

  return (
    <>
    <Helmet htmlAttributes={{ lang : 'en-uk' }}>
    <meta charSet="utf-8" />
    <title>chriswesterduin.com</title>
    <meta name="description" content="Portfolio website for developer Chris Westerduin"/>
    <meta name="keywords" content="web developer, London web developer, software engineer, London software engineer, website development, London website development, full stack developer, React, Node, SQL, JavaScript, frontend developer, backend developer, full-stack developer, graduate developer, London graduate developer"/>
    <meta name="author" content="Chris Westerduin"/>
    <link rel="canonical" href="" />
    <meta property="og:title" content='Chris Westerduin' />
    <meta property="og:description" content="Portfolio website for full stack developer Chris Westerduin" />
    <meta name="twitter:image:src" content="https://avatars.githubusercontent.com/u/71259892?v=4?s=400" /><meta name="twitter:card" content="summary" /><meta name="twitter:title" content="chriswesterduin.com" /><meta name="twitter:description" content="Portfolio website for full stack developer Chris Westerduin" />
    <meta property="og:image" content="https://avatars.githubusercontent.com/u/71259892?v=4?s=400" /><meta property="og:type" content="profile" /><meta property="og:title" content="chriswesterduin.com" /><meta property="og:url" content="https://chriswesterduin.com" /><meta property="og:description" content="Portfolio website for full stack developer Chris Westerduin" />
    <meta name="viewport" content="width=device-width, initial-scale=1"/>


    </Helmet>


    <div className="wrapper">
    <title>Home Page</title>
    <div className={`title`}>
    <div>

      <Link className={`${colorStyles.secondary} titleCW`} to={"/"}>CW</Link>
    </div>
      <Nav/>
      <div Style=" font-size: 1.5em"><a className="subLink" target="_blank" rel="noreferrer" href="https://github.com/cwesterduin"><span Style="padding:0 .5rem 0 .5rem; font-size: 1rem">github</span><FontAwesomeIcon   icon={faGithub}/></a></div>
      <div Style="padding:0 1rem"><DarkModeToggle/></div>


    </div>

      <main>
      {props.children}
      </main>
      <footer className={`${colorStyles.background_secondary}`}>
        <small>© 2024 Chris Westerduin</small>
      </footer>
      </div>
      </>
    )
  }

  export default Layout
