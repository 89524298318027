import React, {useState} from "react"
import {Link} from "gatsby"

import Layout from '../components/Layout'


import Image from '../components/Image'
import Tooltip from '../components/Tooltip'
import DarkModeToggle from '../components/DarkModeToggle'

import linksStyles from "../components/basicComponents/links.module.css"
import colorStyles from "../components/basicComponents/colors.module.css"

import projectStyles from "./project.module.css"


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {SiFlask, SiSpring} from 'react-icons/si'
import {faFilePdf, faDatabase, faExternalLinkAlt, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import {faNodeJs, faReact, faGithub, faJsSquare, faAngular} from '@fortawesome/free-brands-svg-icons'

import './layout.css'


const projectsData = [
    {
        title: {full: "alan-macdonald.net", short: "alanmacdonald"},
        tech: ["React", "Gatsby", "NodeJs", "PostgreSQL"],
        img: "alan-website.PNG",
        link: {
            external: "https://www.alanmacdonald.net"
        },
        detail:
            "Website for Scottish artist, Alan Macdonald.",
    },
    {
        title: {full: "mountain-dog.co.uk", short: "mountain-dog"},
        tech: ["React", "Gatsby", "NodeJs", "MySQL", "Spring", "Angular"],
        img: "mountain-dog-img.PNG",
        link: {
            external: "https://www.mountain-dog.co.uk/map",
            github: "https://github.com/cwesterduin/mountain-dog-frontend",
        },
        detail:
            "Website to display photos and map routes, featuring Alfie.",
    },
    {
        title: {full: "rorymacdonald.co.uk", short: "rorymacdonald"},
        tech: ["React", "Gatsby"],
        img: "rorymacdonald-img.PNG",
        link: {
            external: "https://www.rorymacdonald.co.uk",
            github: "https://github.com/cwesterduin/rorymacdonald-website",
        },
        detail:
            "Website for Scottish artist, Rory Macdonald.",
    }
];

function ProjectCard({proj}) {
    return (
        <div className={projectStyles.card_outer}>
            <h3 Style="padding:0;margin:0">{proj.title.full}</h3>

            <div className={projectStyles.link_icons}>
                { proj.link.github ? <Tooltip text={"github"}>
                    <a rel="noreferrer" className={colorStyles.secondary} aria-label="link to project github repository"
                       target="_blank" href={proj.link.github} Style="margin: 0">
                        <FontAwesomeIcon alt="link to project github repository" icon={faGithub}/>
                    </a>
                </Tooltip> : null}
                <Tooltip text={"website"}>
                    <a rel="noreferrer" className={colorStyles.secondary} aria-label="link to project website"
                       target="_blank" href={proj.link.external}>
                        <FontAwesomeIcon alt="link to project website" icon={faExternalLinkAlt}/>
                    </a>
                </Tooltip>
            </div>

            <div className={projectStyles.card}>
                <div className={projectStyles.card_image} Style="">

                    <Image alt="link to for more project info with preview image" filename={proj.img}/>

                </div>

                <div Style="flex: 1;">


                    <p>{proj.detail}</p>


                </div>

            </div>
            <div className={projectStyles.tech_icons}>
                {proj.tech.map((item, index) =>
                    item === 'Gatsby' ?
                        <Tooltip text={item}>
                            <div
                                Style={index !== 0 || index !== proj.tech - 1 ? 'margin:0 .3em; height:1.33em; width:1.33em' : 'height:1.33em; width:1.33em'}>
                                <Image filename={"icon.png"}/>
                            </div>
                        </Tooltip>
                        : item === 'JavaScript' ?
                            <Tooltip text={item}>
                                <div Style={index !== 0 || index !== proj.tech - 1 ? 'margin:0 .3em' : null}>
                                    <FontAwesomeIcon size={"lg"} color={"#f7df1e"} icon={faJsSquare}/>
                                </div>
                            </Tooltip>
                            : item === 'React' ?
                                <Tooltip text={item}>
                                    <div Style={index !== 0 || index !== proj.tech - 1 ? 'margin:0 .3em' : null}>
                                        <FontAwesomeIcon size={"lg"} color={"#61dafb"} icon={faReact}/>
                                    </div>
                                </Tooltip>
                                : item === 'NodeJs' ?
                                    <Tooltip text={item}>
                                        <div Style={index !== 0 || index !== proj.tech - 1 ? 'margin:0 .3em' : null}>
                                            <FontAwesomeIcon size={"lg"} color={"#689f63"} icon={faNodeJs}/>
                                        </div>
                                    </Tooltip>
                                    : item === 'Websockets' ?
                                        <Tooltip text={item}>
                                            <div
                                                Style={index !== 0 || index !== proj.tech - 1 ? 'margin:0 .3em; height:1.33em; width:1.33em' : 'height:1.33em; width:1.33em'}>
                                                <Image filename={"websockets (2).png"}/>
                                            </div>
                                        </Tooltip>
                                        : item === 'Angular' ?
                                            <Tooltip text={item}>
                                                <div Style={index !== 0 || index !== proj.tech - 1 ? 'margin:0 .3em' : null}>
                                                    <FontAwesomeIcon size={"lg"} color={"#d9022e"} icon={faAngular}/>
                                                </div>
                                            </Tooltip>
                                        : ['Database', 'MySQL', "PostgreSQL"].includes(item) ?
                                            <Tooltip text={item}>
                                                <div
                                                    Style={index !== 0 || index !== proj.tech - 1 ? 'margin: 0 .3em' : null}>
                                                    <FontAwesomeIcon size={"lg"} color={"#ebba34"} icon={faDatabase}/>
                                                </div>
                                            </Tooltip>
                                            : item === 'Spring' ?
                                                <Tooltip text={item}>
                                                  <div Style={index !== 0 || index !== proj.tech - 1 ? 'margin: 0 .3em' : null}>
                                                    <SiSpring Style={index !== 0 || index !== proj.tech - 1 ? 'margin:0 .3em; height:1.5em; width:1.5em' : 'height:1.5em; width:1.5em'} color={"#6db340"}/>
                                                  </div>
                                                </Tooltip>
                                            : item === 'Flask' ?
                                                <Tooltip text={item}>
                                                    <div
                                                        Style={index !== 0 || index !== proj.tech - 1 ? 'margin: 0 .3em' : null}>
                                                        <SiFlask
                                                            Style={index !== 0 || index !== proj.tech - 1 ? 'margin:0 .3em; height:1.33em; width:1.33em' : 'height:1.33em; width:1.33em'}
                                                            color={"var(--font-color)"}/>
                                                    </div>
                                                </Tooltip>
                                                :
                                                null
                )}
            </div>
        </div>
    )
}

const index = projectsData.map(proj =>
    <ProjectCard proj={proj}/>
)

const Projects = () => {
    return (
        <Layout>
            <h2 Style="text-align:center; margin-top: 2em;" className={`${colorStyles.primary}`}>PROJECTS</h2>
            <div>
                {index}
            </div>
        </Layout>

    )
}

export default Projects
