import * as React from "react"
import { Link } from "gatsby"

import navStyles from './nav.module.css'
import linksStyles from "./basicComponents/links.module.css"
import colorStyles from "./basicComponents/colors.module.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faDatabase, faExternalLinkAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faNodeJs, faReact, faGithub } from '@fortawesome/free-brands-svg-icons'

import { slide as Menu } from 'react-burger-menu'


function Nav() {
  return (
    <>
    <nav className="desktop-nav">
      <ul className={navStyles.nav_top}>
      </ul>
    </nav>
    </>
  )
}

export default Nav
