import React, { useCallback, useState } from 'react';

const DarkModeToggle = () => {

  const [checked, setChecked] = useState(typeof window !== 'undefined' ? window.__theme === 'dark' : false);
  const onChange = useCallback(
    e => {
      const isChecked = e.target.checked;
      setChecked(isChecked);
      if (typeof window !== 'undefined') {
        window.__setPreferredTheme(isChecked ? 'dark' : 'light')
      }
      else {}
    },
    [setChecked]
  );

  return typeof window === 'undefined' ? 
  <>
  <label htmlFor="toggle">darkmode</label>
  </> : 
  <>
  <label htmlFor="toggle">darkmode</label>
  <input type="checkbox" id="toggle" checked={checked} onChange={onChange} />
  </>
};

export default DarkModeToggle;