import React, { useState } from "react"

import colorStyles from "./basicComponents/colors.module.css"
import textStyles from "./basicComponents/text.module.css"


function Tooltip(props) {
  const [hover, setHover] = useState(false)
  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} Style={'position: relative; display: flex; flex-direction: column; max-width: max-content'}>
    {props.children}
    <div Style={`display: ${hover ? 'block' : 'none'}`} className={`${textStyles.tooltip} ${colorStyles.secondary} ${colorStyles.background_primary}`}>{props.text}</div>
    </div>
  )
}


export default Tooltip
